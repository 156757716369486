import React, { useState } from "react";
import Wheel from "./Wheel/Wheel";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";

export interface IUser {
    name: string;
    age: number;
}
export const MainApp = () => {
    return (
        <>
            <Wheel />
            <ToastContainer />
        </>
    );
};
