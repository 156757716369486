import React, { useState, useRef, useEffect, useCallback } from "react";
import Segment from "./Segment";
import SpinButton from "./SpinButton";
import { API_URL } from "../../helpers/Constants";
import { toast } from "react-toastify";

export interface ApiResponse {
    winningPrize: string;
}

export interface SegmentData {
    name: string;
}

export const initialSegments: SegmentData[] = [
    { name: "Prize 1" },
    { name: "Prize 2" },
    { name: "Prize 3" },
    { name: "Prize 4" },
    { name: "Prize 5" },
    { name: "Prize 6" },
];

const Wheel: React.FC = () => {
    const [segments, setSegments] = useState<SegmentData[] | undefined | null>(
        undefined
    );
    const [isLoading, setLoading] = useState<Boolean>(false);
    // const [totalQuantityWon, setTotalQuantityWon] = useState<number>(0);
    const [spinDegrees, setSpinDegrees] = useState<number>(0);
    const [isSpinning, setIsSpinning] = useState<boolean>(false);
    const [result, setResult] = useState<string>("");
    const intervalRef = useRef<NodeJS.Timeout | null>(null);
    const [winningSegment, setWinningSegment] = useState<number | undefined>(
        undefined
    );
    const audioRef = useRef<HTMLAudioElement | null>(null);

    useEffect(() => {
        if (segments === undefined) {
            setLoading(true);
            fetch(API_URL + "segments")
                .then((res) => res.json() as Array<SegmentData>)
                .then(setSegments)
                .then(() => setLoading(false));
        }
    }, []);

    const getSegmentByName = (name: string): SegmentData | undefined => {
        return (segments &&
            segments.find((segment) => segment.name === name)) as
            | SegmentData
            | undefined;
    };

    const fetchWinningPrize = async (): Promise<SegmentData> => {
        // Replace with actual API call
        return new Promise((resolve, reject) => {
            setTimeout(async () => {
                const winningPrize = await fetch(API_URL + "spin")
                    .then((res) => res.json() as SegmentData)
                    .catch(reject);
                console.info(winningPrize);
                resolve({ winningPrize });
            }, 2000);
        });
    };

    const spinWheel = async () => {
        if (isSpinning || !segments) return;
        setWinningSegment(undefined);
        setIsSpinning(true);

        // Start continuous rotation
        const interval = 20; // 20 ms interval for smoother animation
        let currentDegrees = spinDegrees;
        intervalRef.current = setInterval(() => {
            currentDegrees = (currentDegrees + 10) % 360;
            setSpinDegrees(currentDegrees);
        }, interval);

        // Play spinner sound
        if (audioRef.current) {
            audioRef.current.play();
        }

        // Fetch winning prize from API
        const { winningPrize } = await fetchWinningPrize();
        const chosenSegment = getSegmentByName(winningPrize.name);
        if (!chosenSegment) {
            console.error("No items available for this hour");
            toast.error("No items available for this hour");
            clearInterval(intervalRef.current!);
            setIsSpinning(false);
            if (audioRef.current) {
                audioRef.current.pause();
                audioRef.current.currentTime = 0;
            }
            return;
        }

        const segmentIndex = segments.findIndex(
            (segment) => segment.name === chosenSegment.name
        );

        const degreesPerSegment = 360 / segments.length;
        // add offset for randomness
        const randomOffset = Math.random() * degreesPerSegment;
        const stopDegrees = 1440 - segmentIndex * degreesPerSegment; // Ensuring at least 4 full rotations

        clearInterval(intervalRef.current!);

        let remainingDegrees = stopDegrees - currentDegrees;
        if (remainingDegrees < 0) remainingDegrees += 360;
        const slowDownInterval = setInterval(() => {
            if (Math.round(remainingDegrees) <= 0) {
                setWinningSegment(segmentIndex);
                clearInterval(slowDownInterval);
                setIsSpinning(false);
                toast(`You Won ${chosenSegment.name}`);
                // Stop spinner sound
                if (audioRef.current) {
                    audioRef.current.pause();
                    audioRef.current.currentTime = 0;
                }
            } else {
                currentDegrees = (currentDegrees + remainingDegrees / 20) % 360;
                setSpinDegrees(currentDegrees);
                remainingDegrees -= remainingDegrees / 20;
            }
        }, interval);
    };

    useEffect(() => {
        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, []);

    const getTextColor = useCallback(
        (index: number): string => {
            if (index === winningSegment) {
                return "#FFF";
            }

            return index % 2 === 0 ? "#000" : "#F4C92D";
        },
        [winningSegment]
    );

    return (
        <>
            <audio ref={audioRef} src="../../assets/spin.wav" loop />
            {isLoading ? (
                "Loading"
            ) : (
                <>
                    <div className="spinner-main-div d-flex align-items-center justify-center">
                        <div className="spinner-section position-relative">
                            <div
                                className="spin-container"
                                style={{
                                    transform: `rotate(${spinDegrees}deg)`,
                                }}
                            >
                                {segments?.map((segment, index) => (
                                    <Segment
                                        key={index}
                                        name={segment.name.toUpperCase()}
                                        backgroundColor={
                                            index % 2 === 0 ? "#F4C92D" : "#000"
                                        }
                                        rotate={
                                            index * (360 / segments!.length)
                                        }
                                        textColor={getTextColor(index)}
                                    />
                                ))}
                            </div>
                            <div className="arrow"></div>
                            <SpinButton onClick={spinWheel} />
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default Wheel;
