import React, { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import "./components/App.css";
import { MainApp } from "./components/MainApp";
import App from "./App";
// import { App } from "./components/App";

const rootElement = document.getElementById("app");
const root = createRoot(rootElement);
root.render(
    // <App />
    <React.StrictMode>
        <MainApp />
        {/* <App /> */}
    </React.StrictMode>
);
